import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import SkuTile from "../../components/SkuTile"

import styles from "./ss22.module.scss"


const FallWinter23 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
            "glare-100-soft-patent-pumps",
            "flare-55-soft-patent-pumps",
            "outlined-75-nappa-sandals",
            "stand-out-75-nappa-pumps",
            "balanced-10-nappa-flats",
            "stow-stud-10-nappa-flats",
            "forever-pump-100-nappa-pumps",
            "forever-pump-85-nappa-pumps",
            "forever-pump-55-nappa-pumps",
            "forever-ankle-85-nappa-boots",
            "forever-knee-high-85-nappa-boots",
            "escalate-130-specchio-sandals",
            "escalate-130-satin-sandals",
            "unified-100-knit-fabric-boots",
            "heights-ankle-125-capretto-boots",
            "kickback-mid-calf-65-nappa-boots-1",
            "perfecto-85-vitello-boots",
            "traveler-50-vitello-boots",
            "wanderer-millenium-calf-boots",
            "legacy-mid-calf-75-soft-patent-boots",
            "legacy-fitted-100-soft-patent-boots",
            "legacy-knee-high-100-soft-patent-boots",
            "legacy-knee-high-75-vitello-boots",
    ].filter(handle => nodes.find(style => style.handle === handle))
      .map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        sku.handle = style.handle
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  return (
    <div className={styles.ss22}>
      <SEO
        title="Fall/Winter '23"
        description="New Collection"
        url={location.pathname}
      />

      <header className={styles.header}>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/FW23_LP_desktop_1_2000x.jpg?v=1690417673",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/FW23_LP_mobile_1_850x.jpg?v=1690417672",
          }}
          altText="Fall/Winter '23 Image"
          className={styles.imgFullWidth}
        />
        <Heading
          tag="h1"
          level="0"
          className={styles.heading}
        >
          Fall/Winter '23
        </Heading>
      </header>

      <ul className={styles.grid1ss23}>
        {allSkus.filter(sku => [
            "glare-100-soft-patent-pumps",
            "flare-55-soft-patent-pumps",
            "outlined-75-nappa-sandals",
            "stand-out-75-nappa-pumps",
            "balanced-10-nappa-flats",
            "stow-stud-10-nappa-flats",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw23`}
            />
          ))}
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/FW23_LP_desktop_2_2000x.jpg?v=1690417673",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/FW23_LP_mobile_2_850x.jpg?v=1690417672",
        }}
        altText="Fall/Winter '23 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid1ss23}>
        {allSkus.filter(sku => [
            "forever-pump-100-nappa-pumps",
            "forever-pump-85-nappa-pumps",
            "forever-pump-55-nappa-pumps",
            "forever-ankle-85-nappa-boots",
            "forever-knee-high-85-nappa-boots",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw23`}
            />
          ))}
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/FW23_LP_desktop_3_2000x.jpg?v=1690417673",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/FW23_LP_mobile_3_850x.jpg?v=1690417672",
        }}
        altText="Fall/Winter '23 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid1ss23}>
        {allSkus.filter(sku => [
            "escalate-130-specchio-sandals",
            "escalate-130-satin-sandals",
            "unified-100-knit-fabric-boots",
            "heights-ankle-125-capretto-boots",
            "kickback-mid-calf-65-nappa-boots-1",
            "perfecto-85-vitello-boots",
            "traveler-50-vitello-boots",
            "wanderer-millenium-calf-boots",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw23`}
            />
          ))}
      </ul>

      <Image
        image={{
          desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/FW23_LP_desktop_4_2000x.jpg?v=1690417673",
          mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/FW23_LP_mobile_4_850x.jpg?v=1690417672",
        }}
        altText="Fall/Winter '23 Image"
        className={styles.imgFullWidth}
      />

      <ul className={styles.grid1ss23}>
        {allSkus.filter(sku => [
            "legacy-mid-calf-75-soft-patent-boots",
            "legacy-fitted-100-soft-patent-boots",
            "legacy-knee-high-100-soft-patent-boots",
            "legacy-knee-high-75-vitello-boots",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-fw23`}
            />
          ))}
      </ul>
    </div>
  )
}

export default FallWinter23

export const query = graphql`
    query FallWinter23Query {
        allProductStyle(filter: {handle: {in: [
            "glare-100-soft-patent-pumps",
            "flare-55-soft-patent-pumps",
            "outlined-75-nappa-sandals",
            "stand-out-75-nappa-pumps",
            "balanced-10-nappa-flats",
            "stow-stud-10-nappa-flats",
            "forever-pump-100-nappa-pumps",
            "forever-pump-85-nappa-pumps",
            "forever-pump-55-nappa-pumps",
            "forever-ankle-85-nappa-boots",
            "forever-knee-high-85-nappa-boots",
            "escalate-130-specchio-sandals",
            "escalate-130-satin-sandals",
            "unified-100-knit-fabric-boots",
            "heights-ankle-125-capretto-boots",
            "kickback-mid-calf-65-nappa-boots-1",
            "perfecto-85-vitello-boots",
            "traveler-50-vitello-boots",
            "wanderer-millenium-calf-boots",
            "legacy-mid-calf-75-soft-patent-boots",
            "legacy-fitted-100-soft-patent-boots",
            "legacy-knee-high-100-soft-patent-boots",
            "legacy-knee-high-75-vitello-boots"
        ]}}) {
            nodes {
                ...PLPStyleFragment
                handle
            }
        }
    }
`
